.rc-tooltip .rc-tooltip-content .rc-tooltip-inner {
  padding: 4px 9px;
  color: #fff;
  text-align: left;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.1px;
  background-image: linear-gradient(to right, #FB8400, #ff9901);
  border-radius: 6px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.35);
  min-height: 30px;
  font-family: Montserrat;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow, .rc-tooltip-placement-bottomLeft .rc-tooltip-arrow, .rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  top: 4px;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #FB8400;
}