@import url('./Assets/itau-display-pro/itau-display-pro.css');

html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Itau Display Pro';
  font-size: 1rem;
  line-height: 1.5;
  /* background-image: linear-gradient(#09184c, #171f4b 66%, #171f4b); */
  background: white;
}

.BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track {
  align-items: flex-start;
}

body.non-scroll {
  overflow: hidden;
}

button:disabled {
  opacity: 0.5;
}

@keyframes cielo-anim {
  from {
    transform: translateX(0);
  }
  to {
    transform: translate(-3840px);
  }
}

@keyframes cielo-anim-reverse {
  from {
    transform: translateX(-3840px)
  }
  to {
    transform: translateX(0);
  }
}


@keyframes nubes-lejos-anim {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-1920px);
  }
}

@keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes infinite-spinning-reverse {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}


@keyframes globo-loader {
  0% {
    left: calc(50% - 50px);
    bottom: 176px;
  }
  25% {
    left: calc(50% - 35px);
    bottom: 264px;
  }
  50% {
    left: calc(50% - 50px);
    bottom: 352px;
  }
  75% {
    left: calc(50% - 80px);
    bottom: 264px;
  }
  100% {
    left: calc(50% - 50px);
    bottom: 176px;
  }
}

@keyframes baloon-movement {
  0% {
    transform: translateY(-50px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-50px);
  }
}

@keyframes baloon-movement_centered {
  0% {
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-30px);
  }
}

@keyframes footer-shadow-movement_centered {
  0% {
    transform: scale(.8)
  }
  50% {
    transform: scale(.6)
  }
  100% {
    transform: scale(.8)
  }
}

@keyframes footer-shadow-movement {
  0% {
    width: 40px;
    left: 154px;
  }
  50% {
    left: 161px;
    width: 20px;
  }
  100% {
    width: 40px;
    left: 154px;
  }
}

@keyframes loader-shadow-movement {
  0% {
    width: 40px;
    left: 260px;
  }
  50% {
    left: 270px;
    width: 20px;
  }
  100% {
    width: 40px;
    left: 260px;
  }
}

@keyframes baloon-movement-loader {
  0% {
    top: 0;
  }
  50% {
    margin-top: -20px;
  }
  100% {
    margin-top: 0;
  }
}

/*


*/
