@font-face {
    font-family: 'Itau Display Pro';
    src: url('EOT/ItauDisplayPro_W_Bd.eot'); /* IE9 Compat Modes */
    src: url('EOT/ItauDisplayPro_W_Bd.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('WOFF2/ItauDisplayPro_W_Bd.woff2') format('woff2'), /* Super Modern Browsers */
         url('WOFF/ItauDisplayPro_W_Bd.woff') format('woff'); /* Pretty Modern Browsers */
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Itau Display Pro';
    src: url('EOT/ItauDisplayPro_W_XBd.eot'); /* IE9 Compat Modes */
    src: url('EOT/ItauDisplayPro_W_XBd.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('WOFF2/ItauDisplayPro_W_XBd.woff2') format('woff2'), /* Super Modern Browsers */
         url('WOFF/ItauDisplayPro_W_XBd.woff') format('woff'); /* Pretty Modern Browsers */
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'Itau Display Pro';
    src: url('EOT/ItauDisplayPro_W_Lt.eot'); /* IE9 Compat Modes */
    src: url('EOT/ItauDisplayPro_W_Lt.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('WOFF2/ItauDisplayPro_W_Lt.woff2') format('woff2'), /* Super Modern Browsers */
         url('WOFF/ItauDisplayPro_W_Lt.woff') format('woff'); /* Pretty Modern Browsers */
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Itau Display Pro';
    src: url('EOT/ItauDisplayPro_W_Rg.eot'); /* IE9 Compat Modes */
    src: url('EOT/ItauDisplayPro_W_Rg.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('WOFF2/ItauDisplayPro_W_Rg.woff2') format('woff2'), /* Super Modern Browsers */
         url('WOFF/ItauDisplayPro_W_Rg.woff') format('woff'); /* Pretty Modern Browsers */
    font-weight: 400;
    font-style: normal;
  }